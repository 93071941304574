// @ts-check
import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import { graphql, useStaticQuery } from "gatsby"

import { CardHeader } from "../../components/cards/tools/cardHeader"
import { CardImageText } from "../../components/cards/tools/cardImageText"
import { CardWorkflows } from "../../components/cards/tools/cardWorkflows"
import { CardFeedbackLoops } from "../../components/cards/tools/cardFeedbackLoops"
import Layout from "../../components/layout"
import { CardSelfService } from "../../components/cards/tools/cardSelfService"
import { CardShowTalkPointDraw } from "../../components/cards/tools/cardShowTalkPointDraw"
import styled from "@emotion/styled"
import { DesktopMediaQuery } from "../../styles/constants"
import AgentFeedback from "../../components/illustrations/agentFeedback"
import SebastianPfahler from "../../components/cards/testimonials/testimonialSebastian"
import { CardFullWidth } from "../../components/cards/cardFullWidth"
import { trackEvent } from "../../utils/trackEvent"
import SEO from "../../components/seo"

const BottomImageWrapper = styled.div`
  ${DesktopMediaQuery} {
    display: flex;
    height: 100%;
    align-items: flex-end;
  }
`

const LiveSupport = () => {
  const images = useStaticQuery(graphql`
    query {
      oneClickVideo: file(relativePath: { eq: "tools/one-click-video.png" }) {
        childImageSharp {
          fluid(
            maxWidth: 1240
            quality: 80
            traceSVG: { background: "transparent", color: "#232A32" }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      powerToTheAgent: file(
        relativePath: { eq: "tools/power-to-the-agent.png" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 1240
            quality: 80
            traceSVG: { background: "transparent", color: "#232A32" }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      heroImage: file(relativePath: { eq: "tools/live-support-drone.png" }) {
        childImageSharp {
          fluid(
            maxWidth: 1500
            quality: 80
            traceSVG: {
              color: "#F96443"
              background: "#333"
              blackOnWhite: false
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  console.log("images", images)

  return (
    <Layout newsletter={null} activeTab="product">
      <SEO title="Live support" />
      <CardHeader
        fluid={images.heroImage.childImageSharp.fluid}
        variant="primary"
        titleTextColor="primary"
        ratio={[3, 5]}
        breadcrumb="Live support"
        title={
          <>
            Better than <br /> being there
          </>
        }
        description="Live remote support is not only safer, faster and cheaper compared
            to field-service. Features like decision support and agent
            collaboration also make it smarter."
      />
      <CardShowTalkPointDraw />
      <CardImageText
        textColor="primary"
        isReverseRow
        variant="lightgrey"
        title="One-click video support"
        bodyText="Mavenoid’s live support via video or chat runs in the browser, and
            needs no downloads or installation. It works for every customer and
            without delay. Less friction and more time for problem-solving."
        img={
          <Img
            fluid={images.oneClickVideo.childImageSharp.fluid}
            objectFit="contain"
            alt="A stylized version of the Mavenoid user interface"
            style={{ width: "100%", margin: "1rem 0 1rem 0" }}
          />
        }
      />
      <CardImageText
        textColor="primary"
        variant="lightgrey"
        title={
          <>
            Power to <br /> the agent
          </>
        }
        bodyText="The agent dashboard is optimized for technical product support and
            built around the needs of agents. Jump into focused troubleshooting
            via chat or video, without losing track of other active
            conversations and what the rest of the team is doing."
        img={
          <BottomImageWrapper>
            <Img
              fluid={images.powerToTheAgent.childImageSharp.fluid}
              objectFit="contain"
              alt="A stylized version of the Mavenoid user interface"
              style={{ width: "100%" }}
            />
          </BottomImageWrapper>
        }
      />
      <CardImageText
        textColor="primary"
        isReverseRow
        variant="lightgrey"
        title="Fast feedback loops"
        bodyText=" As tickets are solved, agents make the product assistant smarter by
            telling it what worked. This continuous input quickly improves both
            self-service and live support."
        img={<AgentFeedback />}
      />
      <CardWorkflows />
      <CardFeedbackLoops />
      <SebastianPfahler />
      <CardFullWidth
        title="Experience support that really works"
        description={
          <div style={{ maxWidth: "440px" }}>
            Unlike generic support tools that focus on deflection, Mavenoid is
            all about solutions.
            <br />
            <br /> Schedule a demo and experience how Mavenoid meets customers
            with the right level of support to take them seamlessly from request
            to solution.
          </div>
        }
        trackTryNow={() => {
          trackEvent({
            category: "sign_up_redirect",
            label: "live support try free try now",
            siteSection: "live_support_try_now_banner",
          })
        }}
      />
      <CardSelfService />
    </Layout>
  )
}

export default LiveSupport
