import React from "react"
import styled from "@emotion/styled"

import colors from "../../../styles/colors"
import Typography, { typographyStyles } from "../../typography"
import Workflow1 from "../../icons/workflow-1"
import Workflow2 from "../../icons/workflow-2"
import Workflow3 from "../../icons/workflow-3"
import Workflow4LS from "../../icons/workflow-4-ls"
import BigArrow from "../../icons/bigArrow"
import Card from "../../card"
import { MobileMediaQuery } from "../../../styles/constants"

const Wrapper = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
`

const HorizontalListWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  ${MobileMediaQuery} {
    overflow: scroll;
    justify-content: flex-start;
    margin-top: 2rem;
  }
`
const HorizontalListItem = styled.div`
  margin: ${p => (p.isFirstItem ? "1rem 1rem 1rem 0" : "1rem")};
  display: flex;
  align-items: center;
  flex-direction: column;
`

const ImageWrapper = styled.div`
  width: 128px;
  height: 128px;
  margin-bottom: 1.625rem;
`

const BodyText = styled(Typography)`
  ${MobileMediaQuery} {
    ${typographyStyles.bodySmall}
  }
`

export const CardFeedbackLoops = () => {
  const horizontalListContent = [
    {
      image: <Workflow1 />,
      imageText: (
        <>
          your customers <br /> need support
        </>
      ),
    },
    {
      image: <Workflow2 />,
      imageText: (
        <>
          they contact you <br /> through any channel
        </>
      ),
    },
    {
      image: <Workflow3 />,
      imageText: (
        <>
          you help your <br />
          customers remotely
        </>
      ),
    },
    {
      image: <Workflow4LS />,
      imageText: (
        <>
          feedback loops <br />
          improve the system
        </>
      ),
    },
  ]

  return (
    <Wrapper variant="lightgrey" textColor="primary">
      <Top>
        <Typography variant="h3">
          Feedback loops work across the platform
        </Typography>
        <BodyText
          variant="bodyLarge"
          style={{
            color: colors.darkgrey,
            marginTop: "2rem",
            maxWidth: "650px",
          }}
        >
          When requests are resolved, either by humans or machine, new data is
          fed back into the system. It keeps content up to date, makes
          troubleshooting more accurate and improves decision support.
        </BodyText>
      </Top>
      <HorizontalListWrapper>
        {horizontalListContent.map((item, i) => (
          <React.Fragment key={i}>
            <HorizontalListItem isFirstItem={i === 0}>
              <ImageWrapper>{item.image}</ImageWrapper>
              <Typography
                variant="overline"
                style={{ fontSize: "12px", textAlign: "center" }}
              >
                {item.imageText}
              </Typography>
            </HorizontalListItem>
            {i < horizontalListContent.length - 1 && (
              <BigArrow
                style={{
                  minWidth: "48px",
                  width: "48px",
                  height: "164px",
                  margin: "0 0.5rem",
                }}
              />
            )}
          </React.Fragment>
        ))}
      </HorizontalListWrapper>
    </Wrapper>
  )
}
