import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import colors from "../../../styles/colors"
import Card from "../../card"
import { Testimonial } from "../../testimonial"
import { BSH } from "../cardPartners"

const SebastianPfahler = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "customers/sebastian_pfahler.png" }) {
        childImageSharp {
          fixed(height: 209) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Card variant="white" noMinHeight>
      <Testimonial
        name="Sebastian Pfahler"
        workTitle="Contact Center"
        department="Digitalization"
        company="BSH Home Appliances"
        profilePictureData={data}
        logo={<BSH fill={colors.darkgrey} style={{ height: "30px" }} />}
        linkTo="/customers/bsh/"
        description={`“Mavenoid handles our internal complexity and quickly adapts to our
            global needs.”`}
      />
    </Card>
  )
}

export default SebastianPfahler
