// @ts-check
import React from "react"

const LiveSupportIcons = (/** @type {React.SVGAttributes} */ { ...props }) => (
  <svg viewBox="0 0 1440 275" fill="currentColor" {...props}>
    <path d="M1112.75 123.548L995.978 5.96437V5.72092C988.397 -1.90697 976.14 -1.90697 968.559 5.72092L851.548 123.466C843.967 131.094 843.967 143.429 851.548 151.057C859.128 158.685 871.386 158.685 878.966 151.057L962.753 66.7441V254.926C962.753 265.719 971.462 274.483 982.188 274.483C992.913 274.483 1001.62 265.719 1001.62 254.926V66.8253L1085.41 151.138C1092.99 158.766 1105.25 158.766 1112.83 151.138C1120.41 143.51 1120.41 131.175 1112.75 123.548Z" />
    <path d="M118.164 28.5549C45.6869 28.5549 -16.1428 73.6457 -41.1831 137.243C-16.1428 200.84 45.7603 245.93 118.164 245.93C190.568 245.93 252.471 200.84 277.511 137.243C252.471 73.5722 190.642 28.5549 118.164 28.5549ZM118.164 209.652C78.1438 209.652 45.6869 177.193 45.6869 137.169C45.6869 97.1457 78.1438 64.6863 118.164 64.6863C158.185 64.6863 190.642 97.1457 190.642 137.169C190.642 177.193 158.185 209.652 118.164 209.652ZM74.6925 137.169C74.6925 113.082 94.0785 93.6941 118.164 93.6941C142.25 93.6941 161.636 113.082 161.636 137.169C161.636 161.257 142.25 180.644 118.164 180.644C94.0785 180.644 74.6925 161.257 74.6925 137.169Z" />
    <path d="M1335.82 233.776L1255.68 259.754C1245.71 260.708 1237.27 252.269 1238.23 242.289L1264.18 162.08C1264.55 158.338 1266.16 154.889 1268.8 152.247L1399.76 21.2571C1405.99 15.0195 1416.18 15.0195 1422.42 21.2571L1476.6 75.4876C1482.84 81.7252 1482.84 91.9255 1476.6 98.1631L1345.72 229.153C1343.01 231.795 1339.56 233.409 1335.82 233.776Z" />
    <path d="M636.803 38.7112C609.473 15.4585 582.002 24.9292 561.876 42.033C541.749 24.9292 514.278 15.4585 486.948 38.7112C459.619 61.9639 396.485 138.295 396.485 138.295C396.485 138.295 464.986 250.106 561.946 250.106C658.907 250.106 727.267 138.295 727.267 138.295C727.267 138.295 664.133 61.9639 636.803 38.7112ZM561.876 158.721C534.334 158.721 502.132 134.761 502.132 134.761C502.132 134.761 534.263 110.802 561.876 110.802C589.488 110.802 621.62 134.761 621.62 134.761C621.62 134.761 589.417 158.721 561.876 158.721Z" />
  </svg>
)

export default LiveSupportIcons
