// @ts-check
import React from "react"

const Workflow4LS = (/** @type {React.SVGAttributes} */ { ...props }) => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
    {...props}
  >
    <rect width="128" height="128" rx="24" fill="white" />
    <path d="M100.882 56.7453C101.429 57.2921 102.315 57.2921 102.862 56.7453L111.772 47.8358C112.318 47.289 112.318 46.4026 111.772 45.8559C111.225 45.3091 110.338 45.3091 109.792 45.8559L101.872 53.7755L93.9525 45.8559C93.4058 45.3091 92.5193 45.3091 91.9726 45.8559C91.4259 46.4026 91.4259 47.289 91.9726 47.8358L100.882 56.7453ZM26.9899 70.3821C26.4432 69.8354 25.5568 69.8354 25.0101 70.3821L16.1005 79.2917C15.5538 79.8384 15.5538 80.7248 16.1005 81.2716C16.6472 81.8183 17.5337 81.8183 18.0804 81.2716L26 73.352L33.9196 81.2716C34.4663 81.8183 35.3528 81.8183 35.8995 81.2716C36.4462 80.7248 36.4462 79.8384 35.8995 79.2917L26.9899 70.3821ZM34 26.4H93.8721V23.6H34V26.4ZM93.8721 100.727H34V103.527H93.8721V100.727ZM100.472 33V55.7554H103.272V33H100.472ZM100.472 68.1531V82.8456H103.272V68.1531H100.472ZM100.472 82.8456V94.1274H103.272V82.8456H100.472ZM27.4 94.1274V71.3721H24.6V94.1274H27.4ZM27.4 44.2819V33H24.6V44.2819H27.4ZM27.4 58.9743V44.2819H24.6V58.9743H27.4ZM34 100.727C30.3549 100.727 27.4 97.7725 27.4 94.1274H24.6C24.6 99.3189 28.8085 103.527 34 103.527V100.727ZM93.8721 103.527C99.0636 103.527 103.272 99.3189 103.272 94.1274H100.472C100.472 97.7725 97.5172 100.727 93.8721 100.727V103.527ZM93.8721 26.4C97.5172 26.4 100.472 29.3549 100.472 33H103.272C103.272 27.8085 99.0636 23.6 93.8721 23.6V26.4ZM34 23.6C28.8085 23.6 24.6 27.8085 24.6 33H27.4C27.4 29.3549 30.3549 26.4 34 26.4V23.6Z" />
    <mask id="path-3-inside-1" fill="white">
      <path d="M69 66.9119C73.1311 65.0159 76 60.843 76 56C76 49.3726 70.6274 44 64 44C57.3726 44 52 49.3726 52 56C52 60.843 54.8689 65.0159 59 66.9119V70H69V66.9119Z" />
    </mask>
    <path
      d="M69 66.9119L67.832 64.3671L66.2 65.1162V66.9119H69ZM59 66.9119H61.8V65.1162L60.168 64.3671L59 66.9119ZM59 70H56.2V72.8H59V70ZM69 70V72.8H71.8V70H69ZM70.168 69.4567C75.2567 67.1211 78.8 61.9773 78.8 56H73.2C73.2 59.7087 71.0054 62.9107 67.832 64.3671L70.168 69.4567ZM78.8 56C78.8 47.8262 72.1738 41.2 64 41.2V46.8C69.081 46.8 73.2 50.919 73.2 56H78.8ZM64 41.2C55.8262 41.2 49.2 47.8262 49.2 56H54.8C54.8 50.919 58.919 46.8 64 46.8V41.2ZM49.2 56C49.2 61.9773 52.7433 67.1211 57.832 69.4567L60.168 64.3671C56.9946 62.9107 54.8 59.7087 54.8 56H49.2ZM61.8 70V66.9119H56.2V70H61.8ZM69 67.2H59V72.8H69V67.2ZM66.2 66.9119V70H71.8V66.9119H66.2Z"
      mask="url(#path-3-inside-1)"
    />
    <line
      x1="59"
      y1="73.6"
      x2="69"
      y2="73.6"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <line
      x1="62"
      y1="78.6"
      x2="66"
      y2="78.6"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
)

export default Workflow4LS
