import React from "react"
import styled from "@emotion/styled"

import colors from "../../../styles/colors"
import Card from "../../card"
import Typography from "../../typography"
import LiveSupportIcons from "../../icons/LiveSupportIcons"

const Wrapper = styled.div`
  overflow: hidden;
  margin-top: 4rem;
  margin-left: -4px;
  margin-right: -4px;
`
const IconWrapper = styled.div`
  color: ${colors.primary};
  display: flex;
`

export const CardShowTalkPointDraw = () => (
  <Wrapper>
    <IconWrapper>
      <LiveSupportIcons style={{ width: "100%" }} />
    </IconWrapper>
    <Card noMinHeight variant="white">
      <Typography variant="h3">
        <span style={{ color: colors.primary }}>Show. Talk. Point. Draw.</span>
        <br />
        Just ask your customers to point their smartphone’s video camera at the
        problem. Draw on the screen, pause it, zoom in, share visual guides.
        Remote support has never been more intuitive.
      </Typography>
    </Card>
  </Wrapper>
)
